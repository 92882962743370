<template>
  <div>
    <v-data-iterator
      :items="customersList"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      :sort-by="sortBy.toLowerCase()"
      :sort-desc="sortDesc"
      hide-default-footer
      no-data-text="Nessun cliente"
      no-results-text="Nessun cliente corrispondente"
    >
      <template v-slot:header>
        <v-toolbar
          dark
          color="primary"
          class="mb-1"
        >
          <v-text-field
            v-model="search"
            clearable
            flat
            dense
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Cerca"
          ></v-text-field>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer></v-spacer>
            <v-select
              v-model="sortBy"
              flat
              dense
              solo-inverted
              hide-details
              :items="keys"
              prepend-inner-icon="mdi-arrow-up-down"
              label="Sort by"
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn-toggle
              v-model="sortDesc"
              mandatory
            >
              <v-btn
                small
                depressed
                color="primary"
                :value="false"
              >
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn
                small
                depressed
                color="primary"
                :value="true"
              >
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
      </template>

      <template v-slot:default="props">
        <v-row
          class="mt-2"
        >
          <v-col
            v-for="(item,idx) in props.items"
            :key="'A'+idx"
            cols="3"
            lg="3"
            md="4"
            sm="6"
          >
            <v-card>
              <div class="d-flex justify-content-center">
                <procedure-users-avatar
                  class="mx-auto my-2"
                  :user="item"
                  :size="120"
                ></procedure-users-avatar>
              </div>
              <v-card-title
                class="justify-center"
              >
                <p>{{ item.fullName }}</p>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="9"
                  >
                    <div v-html="item.createdAtFormatted"></div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions
                class="justify-center"
              >
                <v-btn
                  class="primary"
                  block
                  :to="{name:'customerDetail',params:{cid:item.id}}"
                >
                  Dettagli
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-pagination
          v-model="page"
          :length="numberOfPages"
        ></v-pagination>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProcedureUsersAvatar from '@/components/procedure/procedure-users-avatar'

export default {
  name: 'Index',
  components: { ProcedureUsersAvatar },
  computed: {
    ...mapGetters(['loggedUser', 'procedureList', 'customersList']),
    numberOfPages() {
      return Math.ceil(this.customersList.length / this.itemsPerPage)
    },
  },
  data() {
    return {
      search: '',
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 12,
      sortBy: 'surname',
      keys: [
        { text: 'Cognome, Nome', value: 'surname' },
        { text: 'Data inserimento', value: 'created_at' },
      ],
      breadCrumb: [
        {
          text: 'Dashboard',
          to: '/dashboard',
        },
        {
          text: 'Clienti',
          to: '/customers',
        },
      ],
    }
  },
  mounted() {
    this.$store.dispatch('setBreadCrumbLevels', this.breadCrumb)
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
  },
}
</script>

<style scoped>

</style>
